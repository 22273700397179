import React from 'react'
import Helmet from 'react-helmet'

import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PageTemplateDetails from '../components/PageTemplateDetails'
import TopNavBar from '../components/TopNavBar'
import CoverImage from '../components/CoverImage'

class PageTemplate extends React.Component {
  render() {
    const { title, subtitle } = this.props.data.site.siteMetadata
    const page = this.props.data.markdownRemark
    const { title: pageTitle, description: pageDescription } = page.frontmatter
    const description = pageDescription !== null ? pageDescription : subtitle
    const navBar = <TopNavBar data={this.props.data} />
    const coverImage = <CoverImage page={page} />

    console.log('title ', pageTitle)
    return (
      <Layout navBar={navBar} coverImage={coverImage}>
        <div>
          <Helmet>
            <title>{`${pageTitle}`}</title>
            <meta name="description" content={description} />
          </Helmet>
          <PageTemplateDetails {...this.props} />
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          github
          rss
          vk
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
        cover_image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1800) {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
        cover_image_title
        cover_image_url
      }
    }
  }
`
