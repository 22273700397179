import React from 'react'
import Img from 'gatsby-image'

import './style.scss'

class CoverImage extends React.Component {
  render() {
    const {
      cover_image: coverImage,
      cover_image_title: coverImageTitle,
      cover_image_url: coverImageURL,
    } = this.props.page.frontmatter
    let coverImageTitleDiv = null
    if (coverImageTitle) {
      if (coverImageURL) {
        coverImageTitleDiv = (
          <div className="coverImageTitle">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={coverImageURL}
              title={coverImageTitle}
            >
              {coverImageTitle}
            </a>
          </div>
        )
      } else {
        coverImageTitleDiv = <div className="coverImageTitle">{coverImageTitle}</div>
      }
    }
    if (coverImage) {
      return (
        <div className="coverImage">
          <Img fluid={coverImage.childImageSharp.fluid} alt={coverImageTitle} />
          {coverImageTitleDiv}
        </div>
      )
    }
    return null
  }
}

export default CoverImage
